<template>
  <img
    @load="onImageLoaded"
    class="absolute max-h-full max-w-full object-contain overflow-hidden rounded-lg shadow-2xl select-none bg-gray-800"
    :src="src"
  />
</template>

<script>
export default {
  name: 'MediaImage',
  props: {
    src: String,
  },
  data() {
    return {}
  },
  methods: {
    onImageLoaded() {
      this.$emit('mediaLoaded')
    },
  },
}
</script>

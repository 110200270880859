<template>
  <vue100vh
    v-if="currentEntry"
    :css="{ height: '100rvh' }"
    class="relative gap-4 md:gap-6 grid grid-rows-1/auto px-3 pt-3 pb-4 md:p-6 w-full select-none"
    :draggable="false"
  >
    <div class="flex h-full w-full items-center justify-center">
      <OptionalMedia
        :src="currentEntry.src"
        :source="currentEntry.source"
        class="h-full"
        :category="currentEntry.category"
        @startLoading="onLoadingStart"
        @loaded="onLoaded"
      />
      <div
        v-if="showFallbackLinks && !mediaLoaded"
        class="absolute top-1/2 z-0"
      >
        <a :href="currentEntry.src" target="_blank" class="hover:underline">
          Open file in new tab →
        </a>
      </div>
      <div
        v-if="showFallbackLinks && !mediaLoaded"
        class="absolute bottom-0 right-0 p-1"
      >
        <button
          @click="onSkipClicked"
          class="text-gray-600 hover:text-white hover:underline py-1 px-2"
        >
          Skip
        </button>
      </div>
    </div>
    <OptionButtons
      :category="currentEntry.category"
      :disabled="!mediaLoaded && !showFallbackLinks"
      @selected="onOptionSelect"
    />
    <div class="absolute top-0 left-0 bg-white bg-opacity-25 w-full h-1">
      <div
        class="bg-green-400 h-full transition-all duration-500"
        :style="{ width: `${progress}%` }"
      ></div>
    </div>
    <Errors :sourceErrors="errors" />
  </vue100vh>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import OptionalMedia from '@/components/OptionalMedia.vue'
import OptionButtons from '@/components/OptionButtons.vue'
import Errors from '@/components/Errors.vue'
import vue100vh from 'vue-100vh-vp'

export default {
  name: 'Quiz',
  components: {
    OptionalMedia,
    OptionButtons,
    vue100vh,
    Errors,
  },
  data() {
    return {
      mediaLoaded: false,
      showFallbackLinks: false,
      showFallbackLinksDelay: 5000,
      showFallbackLinksTimeout: -1,
    }
  },
  computed: {
    ...mapState([
      'renders',
      'entries',
      'voterName',
      'currentEntryIndex',
      'errors',
    ]),
    ...mapGetters(['quizFinished', 'siteUrl']),
    totalEntries() {
      return this.entries.length
    },
    currentEntry() {
      return this.entries[this.currentEntryIndex]
    },
    progress() {
      return (this.currentEntryIndex / this.totalEntries) * 100
    },
  },
  watch: {
    quizFinished(finished) {
      if (finished) {
        this.submitAnswers().then(() => {
          this.$router.push({ path: 'finished' })
        })
      }
    },
  },
  methods: {
    ...mapActions([
      'submitAnswers',
      'resetQuiz',
      'parseEntries',
      'collectErrors',
    ]),
    ...mapMutations(['nextEntry']),
    onOptionSelect(value) {
      this.$store.commit('pushAnswer', {
        voter: this.voterName,
        author: this.currentEntry.author,
        type: this.currentEntry.type,
        category: this.currentEntry.category,
        image: `${this.currentEntry.source === 'local' ? this.siteUrl : ''}${
          this.currentEntry.src
        }`,
        value,
      })
      this.nextEntry()
    },
    onStartOver() {
      this.resetQuiz()
      this.$router.push({ path: '/' })
    },
    onLoadingStart() {
      this.showFallbackLinks = false
      this.mediaLoaded = false
      this.showFallbackLinksTimeout = setTimeout(() => {
        this.onTimeoutEnd()
      }, this.showFallbackLinksDelay)
    },
    onLoaded() {
      this.showFallbackLinks = false
      this.mediaLoaded = true
      if (this.showFallbackLinksTimeout >= 0) {
        clearTimeout(this.showFallbackLinksTimeout)
      }
    },
    onTimeoutEnd() {
      this.showFallbackLinks = true
    },
    onSkipClicked() {
      this.onOptionSelect('_skipped_')
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
  },
  created() {
    if (!this.voterName) {
      this.$router.push({ path: '/' })
      return
    }
    this.parseEntries()
    this.collectErrors()
    if (this.quizFinished) {
      this.$router.push({ path: 'finished' })
      return
    }
  },
}
</script>

<style lang="postcss">
.btn-option {
  @apply font-semibold px-4 py-2 text-lg rounded-md m-2 appearance-none shadow-2xl transition duration-100;
}
.btn-option:focus {
  @apply outline-none shadow-outline;
}
@media (hover: none) {
  .btn-option:hover {
    @apply bg-gray-800;
  }
}
</style>

<template>
  <div
    v-show="sourceErrors.length > 0"
    class="absolute inset-0 bg-black bg-opacity-75 text-left space-y-4 overflow-auto p-4 select-text"
  >
    <div class="px-2 py-0.5 rounded bg-red-500 text-white">
      {{ sourceErrors.length }} errors
    </div>
    <div
      v-for="(error, index) in sourceErrors"
      :key="index"
      class="text-red-500"
    >
      ERROR! {{ error.message }}
      <pre>{{ error.entry }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Errors',
  props: {
    sourceErrors: Array,
  },
}
</script>

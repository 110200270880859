<template>
  <div
    class="flex h-full items-center justify-center max-h-full max-w-full relative w-full overflow-hidden"
  >
    <transition name="fade">
      <MediaImage
        v-if="['local', 'remote'].includes(source) && category !== 'anim'"
        v-show="loaded"
        @mediaLoaded="onMediaLoaded"
        :src="src"
      />
      <MediaVideo
        v-if="['local', 'remote'].includes(source) && category === 'anim'"
        v-show="loaded"
        @mediaLoaded="onMediaLoaded"
        :src="src"
      />
      <MediaGoogle
        v-if="source === 'google'"
        v-show="loaded"
        @mediaLoaded="onMediaLoaded"
        :src="src"
      />
      <MediaIframe
        v-if="source === 'iframe'"
        v-show="loaded"
        @mediaLoaded="onMediaLoaded"
        :src="src"
      />
    </transition>
    <transition name="fade">
      <Preloader :shown="!loaded" />
    </transition>
  </div>
</template>

<script>
import MediaImage from '@/components/MediaImage.vue'
import MediaVideo from '@/components/MediaVideo.vue'
import MediaGoogle from '@/components/MediaGoogle.vue'
import MediaIframe from '@/components/MediaIframe.vue'
import Preloader from '@/components/Preloader.vue'

export default {
  name: 'OptionalMedia',
  components: {
    MediaImage,
    MediaVideo,
    MediaGoogle,
    MediaIframe,
    Preloader,
  },
  props: {
    src: String,
    category: String,
    source: String,
  },
  data() {
    return {
      loaded: false,
    }
  },
  watch: {
    src() {
      this.onMediaLoadingStart()
    },
  },
  methods: {
    onClick() {
      if (this.loaded) {
        this.$emit('pick')
      }
    },
    onMediaLoaded() {
      setTimeout(() => {
        this.loaded = true
        this.$emit('loaded')
      }, 150)
    },
    onMediaLoadingStart() {
      this.loaded = false
      this.$emit('startLoading')
    },
  },
  created() {
    this.onMediaLoadingStart()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="-my-2 focus:outline-none" tabindex="-1" ref="optionButtonsWrap">
    <div v-if="optionsType === 'input'">
      <form
        @submit.prevent="onSubmit(inputValue)"
        class="flex gap-1 items-center justify-center w-full"
      >
        <input
          ref="input"
          autofocus
          type="text"
          v-model="inputValue"
          class="text-lg px-2 py-2 leading-7 text-black font-bold rounded-md"
        />
        <button
          type="submit"
          class="btn-option bg-gray-800 border-b-4 disabled:opacity-50 disabled:cursor-not-allowed"
          :class="optionButtons.input.color"
          :disabled="disabled || !inputValue"
        >
          Submit
        </button>
      </form>
    </div>
    <div v-else>
      <button
        v-for="optionButton in buttonsToShow"
        :key="optionButton.value"
        type="submit"
        class="btn-option bg-gray-800 border-b-4 disabled:opacity-50 disabled:cursor-not-allowed"
        :class="optionButton.color || ''"
        @click="onClick(optionButton.value)"
        :disabled="disabled"
      >
        {{ optionButton.text || optionButton.value }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OptionButtons',
  props: {
    category: String,
    disabled: Boolean,
  },
  data() {
    return {
      optionButtons: {
        competency: {
          none_mlr_sdc: [
            {
              text: 'None',
              value: 0,
              color: 'hover:bg-gray-500 border-gray-600',
            },
            {
              text: 'MLR',
              value: 1,
              color: 'hover:bg-yellow-500 border-yellow-600',
            },
            {
              text: 'SDC',
              value: 2,
              color: 'hover:bg-green-500 border-green-600',
            },
          ],
          none_fd_mdf: [
            {
              text: 'None',
              value: 0,
              color: 'hover:bg-gray-500 border-gray-600',
            },
            {
              text: 'FD',
              value: 1,
              color: 'hover:bg-yellow-500 border-yellow-600',
            },
            {
              text: 'MDF',
              value: 2,
              color: 'hover:bg-green-500 border-green-600',
            },
          ],
          none_mat: [
            {
              text: 'None',
              value: 0,
              color: 'hover:bg-gray-500 border-gray-600',
            },
            {
              text: 'MAT',
              value: 1,
              color: 'hover:bg-green-500 border-green-600',
            },
          ],
        },
        like_dislike: [
          {
            text: '👎',
            value: 0,
            color: 'hover:bg-gray-500 border-gray-600',
          },
          {
            text: '👍',
            value: 1,
            color: 'hover:bg-green-500 border-green-600',
          },
        ],
        '1_12': [
          { value: 1, color: 'hover:bg-gray-500 border-gray-600' },
          { value: 2, color: 'hover:bg-gray-500 border-gray-600' },
          { value: 3, color: 'hover:bg-gray-500 border-gray-600' },
          { value: 4, color: 'hover:bg-gray-500 border-gray-600' },
          { value: 5, color: 'hover:bg-yellow-500 border-yellow-600' },
          { value: 6, color: 'hover:bg-yellow-500 border-yellow-600' },
          { value: 7, color: 'hover:bg-yellow-500 border-yellow-600' },
          { value: 8, color: 'hover:bg-yellow-500 border-yellow-600' },
          { value: 9, color: 'hover:bg-green-500 border-green-600' },
          { value: 10, color: 'hover:bg-green-500 border-green-600' },
          { value: 11, color: 'hover:bg-green-500 border-green-600' },
          { value: 12, color: 'hover:bg-green-500 border-green-600' },
        ],
        input: { value: 12, color: 'hover:bg-green-500 border-green-600' },
      },
      competency_by_category: {
        int: 'none_mlr_sdc',
        ext: 'none_mlr_sdc',
        anim: 'none_mlr_sdc',
        mod: 'none_fd_mdf',
        mat: 'none_mat',
      },
      inputValue: null,
    }
  },
  computed: {
    ...mapState(['optionsType']),
    buttonsToShow() {
      switch (this.optionsType) {
        case 'competency':
          return this.optionButtons.competency[
            this.competency_by_category[this.category] || 'none_mlr_sdc'
          ]
        case '1_12':
          return this.optionButtons['1_12']
        case 'like_dislike':
          return this.optionButtons['like_dislike']
        default:
          return this.optionButtons.competency['none_mlr_sdc']
      }
    },
  },
  methods: {
    onClick(value) {
      this.$emit('selected', value)
      this.$refs.optionButtonsWrap.focus()
    },
    onSubmit(value) {
      this.$emit('selected', value)
      this.inputValue = null
      this.$refs.input.focus()
    },
  },
}
</script>

<template>
  <video
    @canplay="onVideoLoaded"
    autoplay
    controls
    class="absolute max-h-full max-w-full object-contain overflow-hidden rounded-lg shadow-2xl select-none bg-gray-800"
    :src="src"
  />
</template>

<script>
export default {
  name: 'MediaVideo',
  props: {
    src: String,
  },
  data() {
    return {}
  },
  methods: {
    onVideoLoaded() {
      this.$emit('mediaLoaded')
    },
  },
}
</script>

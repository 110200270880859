<template>
  <div v-show="shown" class="preloader">
    <span class="circle-one" />
    <span class="circle-two" />
    <span class="circle-thr" />
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    shown: Boolean,
  },
}
</script>

<style scoped lang="scss">
.preloader {
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
  & > span {
    width: 1rem;
    height: 1rem;
    margin: 0.5rem;
    background: white;
    border-radius: 0.5rem;
    display: inline-block;
    animation: pulse 1s ease-in-out infinite;
    &:nth-child(1) {
      animation-delay: 0.33s;
    }
    &:nth-child(2) {
      animation-delay: 0.66s;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
</style>

<template>
  <div
    class="relative w-full h-full flex justify-center items-center"
    style="
      max-width: calc((100vh - 120px) / 0.5625);
      max-height: calc(100vh - 120px);
      overflow: hidden;
    "
  >
    <div
      class="videoWrapper max-h-full max-w-full select-none"
      :style="{ maxWidth: 'calc((100vh - 120px) / 0.5625)' }"
    >
      <iframe
        :src="src"
        class="overflow-hidden rounded-lg shadow-2xl bg-gray-800"
        allow="autoplay"
        width="1920"
        height="1080"
        :style="{ maxHeight: 'calc(100vh - 120px)' }"
        @load="onMediaLoaded"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaGoogle',
  props: {
    src: String,
  },
  data() {
    return {}
  },
  methods: {
    onMediaLoaded() {
      this.$emit('mediaLoaded')
    },
  },
}
</script>

<style scoped lang="scss">
.videoWrapper {
  padding-bottom: 56.25%;
  height: 0px !important;
  width: 100%;
  position: relative;
  overflow: hidden !important;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
